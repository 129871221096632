import { inject, Injectable } from '@angular/core';
import { filter, Observable, switchMap } from 'rxjs';

import { LaunchDarklyService, LDFeatureKey } from './launch-darkly.service';


@Injectable({
	providedIn: 'root'
})
export class FeatureService {
	readonly #ldService = inject(LaunchDarklyService);

	isFeatureOn(key: LDFeatureKey): Observable<boolean> {
		return this.#ldService.ldInitialized$
			.pipe(
				filter((isInitialized) => !!isInitialized),
				switchMap(() => this.#ldService.getFeatureFlagValue(key)),
			);
	}
}
